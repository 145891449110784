import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/spring-2020-stories/more-stories"
import StoryNav from "../../components/spring-2020-stories/story-nav"
import Related from "../../components/spring-2020-stories/related-stories"

import JengOshiro from '../../images/spring-2020/Jeng-Oshiro.jpg';
import JengOshiro2 from '../../images/spring-2020/Jeng-Oshiro2.jpg';
import JengOshiroHug from '../../images/spring-2020/Jeng-Oshiro-hug.jpg';
// import GinnyOshiro from '../../images/spring-2020/Ginny-Oshiro.jpg';
import BatsonTorres from '../../images/spring-2020/batson-torres.jpg';

import socialBanner from "../../images/spring-2020/social-banners/mentoring-project-rebound-students.jpg";


export default class Index extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var pageTitle = 'Retired Industry Leaders Mentor Project Rebound Students';
    var pageDesc = '“I’m re-learning how to live lifeas a productive member of society,” says Ginny Oshiro. The Cal State Fullerton senior criminal justice major is one of 70 students currently enrolled in Project Rebound, a program that supports the higher education and reintegration of formerly incarcerated individuals.';


    var heroStyles = {
      backgroundColor: '#222',
      backgroundImage: `url(${JengOshiro2})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      color: 'white'
    }

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="spring-2020">
        <Helmet>
          <title>{pageTitle}</title>

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        <div className="hero story animated fadeIn" style={heroStyles}>
          <div className="text">
            <h1 className="animated fadeInDown delay-1s">{pageTitle}</h1>
            <span className="animated fadeInDown delay-1s meta">Story by Lynn Juliano, Photos by Matt Gush</span>
          </div>
          <div className="tint"></div>
        </div>
        <article className="wrap small">
        
          <ScrollAnimation animateIn="fadeIn">
          <p><span className="intro-text">“I’m re-learning how to live life</span> as a productive member of society,” says Ginny Oshiro.</p>
          <p>The Cal State Fullerton senior criminal justice major is one of 70 students currently enrolled in Project Rebound, a program that supports the higher education and reintegration of formerly incarcerated individuals.</p>
          <p>While she’s had mentors to help her navigate higher education, and mentors to help her with recovery from substance abuse, she’s never had a “stand-alone mentor” to help her with everyday challenges.</p>
          {/* <figure className="align-right large">
            <img src={GinnyOshiro} alt="Ginny Oshiro" />
            <figcaption>Ginny Oshiro</figcaption>
          </figure> */}
          <p>“It’s a weird question for someone to ask me, ‘What do you need?’” reflects Oshiro. “It was probably one of the first times that I looked at myself and admitted that I struggle with confidence. Being able to identify the things that I need has given me a lot of power.”</p>
          <p>Enter Janice Jeng, a retired tourism and travel executive and vice president of external relations for the university’s <a href="https://olli.fullerton.edu/" target="_blank">Osher Lifelong Learning Institute</a>.</p>
          <p>Through a mentoring program between OLLI and Project Rebound, Oshiro and Jeng have been meeting regularly for more than a year.</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <figure className="align-wide">
            <img src={JengOshiro} alt="Janice Jeng and Ginny Oshiro sit and talk in front of library" />
            <figcaption>Janice Jeng and Ginny Oshiro</figcaption>
          </figure>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <p>“OLLI is a community of active, older adults, who throughout their careers and lives, have mastered such soft skills as communication and leadership,” explains Jeng. “And then we have this other community of young scholars who have overcome difficulties to enter an educational program. To bring these two communities together is just a win-win.”</p>
          <p>“Just talking to another human being, but also a woman to whom I can relate, affirms my existence,” shares Oshiro. “How I view myself and my place in the world has changed for the better.”</p>
          <p>Oshiro plans to graduate this spring with a bachelor’s degree in criminal justice and a minor in Asian American studies. During her time at CSUF, she has maintained a 4.0 GPA, served as vice chair and treasurer for the College of Humanities and Social Sciences Inter-Club Council, and garnered a fellowship with the Women’s Policy Institute.</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <figure className="align-wide">
            <img src={JengOshiroHug} alt="Janice Jeng and Ginny Oshiro" />
          </figure>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <p>With Jeng’s encouragement, Oshiro applied to 10 graduate programs and was accepted to nine. She plans to attend her top choice, UCI’s Criminology, Law and Society Ph.D. program, in the fall.</p>
          <p>“It’s made me humble in watching these Project Rebound students, after all the difficulties that they’ve encountered, to achieve what they’re achieving,” says Jeng. “They have so much fortitude and courage.”</p>
          <p>Meanwhile, Ed Batson joined OLLI looking for an opportunity to extend his talents beyond retirement. The former Los Angeles Times research director met liberal studies major Sergio Torres at an OLLI-Project Rebound event and offered to mentor him.</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <figure className="align-left large">
            <img src={BatsonTorres} alt="Ed Batson and Sergio Torres" />
            <figcaption>Sergio Torres and Ed Batson</figcaption>
          </figure>
          
          <p>“When I met Sergio, one of the things I could tell was that he had a lot of potential,” says Batson. “I also saw some of myself in Sergio because I had been a nontraditional student in that I didn’t go right out of high school and had to work my way through college.</p>
          <p>“My thought was to just be a sounding board or moral support for him on academics, career planning or anything else.”</p>
          <p>Batson and Torres began meeting once a week, discussing everything from public speaking and resume writing, to how to tie a tie, sports, relationships and the future.</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <p>When Torres had to prepare for a presentation at the National Conference on Higher Education in Prison, he turned to Batson for counsel.</p>
          <p>“Sometimes being a student is hard, and having that person you can always call or text or meet is very powerful,” says Torres. “Ed’s become a really good friend.”</p>
          <p>As Torres has become busier with classes, extracurricular activities and work, the pair get together when his schedule allows.</p>
          
          <Related slug="mentoring-project-rebound-students" />
          
          <p>“I’ve had very little positive male influence in my life. To now have that in abundance is helping me grow as a person, as a man and as a leader,” says Torres.</p>

          <p>For Batson, working with Torres has been the rewarding experience he was seeking in his post-career years.</p>

          <p>“Seeing what Sergio’s doing academically and how he’s developing as a person has been very gratifying to me, where I’d sort of lost that part of my life in retirement,” shares Batson. “I think I’ve probably gotten at least as much, or more, out of this experience than he has.” <span className="end-mark" /></p>

          <p className="learn-more">To learn more about Project Rebound, visit <a href="https://www.fullerton.edu/rebound/" target="_blank">fullerton.edu/rebound</a>.</p>
          </ScrollAnimation>

        </article>
        <MoreStories slug="mentoring-project-rebound-students" />
        <StoryNav slug="mentoring-project-rebound-students" />
      </Layout>
    );
  }
} 